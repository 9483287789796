<template>
  <div>
    <CRow>
      <CCol sm="12">
        <PendingAwardsTable
          :items="awards"
          hover
          striped
          border
          small
          fixed
          caption="Premios Pendientesapp"
          @refresh="refreshItems"
          :permissions="permissions"
        />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import PendingAwardsTable from '../../components/reports/PendingAwardsTable.vue'
import ws from '../../services/awards';
import account_ws from '../../services/account';
import store from '../../store'

export default {
  name: 'ReportPendingAwards',
  components: { PendingAwardsTable },
  data: function () {
		return {
            awards: [],
            permissions: null
        }
  },
  mounted: async function() {
    this.loading();
    let response = await ws.getPendings();

    if(response.type == "success"){
      this.awards = response.data;
    }

    let response2 = await account_ws.getInfo(); 

    if(response2.type == "success"){
      this.permissions = response2.data.user.permissions;
    }

    this.loaded();
  },
  methods: {
    async refreshItems (items){
      this.loading();
      this.managers = await items;
      this.loaded();
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>
